<div *ngIf="workflowInstance" class="flex flex-row justify-content-between align-items-center">
   <div class="flex flex-column">
      <p class="font-700" *ngIf="this.workflowInstance?.startedAt">
         Expediente en campaña desde el
         <span class="font-bold"> {{ this.workflowInstance?.startedAt | timezoneDate }}</span>
      </p>
      <p class="font-700" *ngIf="this.workflowInstance?.schedule">
         Siguiente notificación el
         <span class="font-bold"> {{ this.workflowInstance?.schedule | timezoneDate }}</span>
      </p>
      <p class="font-700" *ngIf="this.workflowInstance?.finishedAt">
         Expediente finalizado el
         <span class="font-bold"> {{ this.workflowInstance?.finishedAt | timezoneDate }}</span>
      </p>
   </div>
   <p-button
      label="Redirección a campaña"
      icon="pi pi-external-link "
      severity="secondary"
      (click)="closeModalAndRedirect()"
   ></p-button>
</div>
<!-- CANVAS -->
<div class="flex no-wrap">
   <div *ngIf="isEditable && isNodeSelector && !isOnlyCanvas" class="flex align-items-start justify-content-start">
      <app-node-drag-drop [workflow]="workflow" [graph]="getGraph()" (nodeDrag)="setDraggedNode($event)">
      </app-node-drag-drop>
   </div>
   <div id="joint-container" class="flex flex-grow-1 align-items-start justify-content-center m-2 flex-column-reverse">
      <div id="myJointPaper" [ngClass]="isEditable && isNodeSelector ? 'top-controller' : ''"></div>
      <app-graph-controllers [isEditable]="isEditable" [graph]="graph!" [paper]="paper!" [panZoom]="panZoom" />
   </div>
   <div
      *ngIf="isEditable && isNodeParameters && !isOnlyCanvas"
      class="flex align-items-start justify-content-center m-2"
   >
      <div class="p-flex-column" id="node-view">
         <app-node-inspector
            [workflow]="workflow"
            [selectedNode]="selectedNode"
            [selectedGoalNode]="this.selectedGoalNode"
            [isEditable]="true"
            [id]="selectedElement?.attributes?.['id']"
            (nodeChange)="handleNodeChange($event)"
            (nodeGoalToSelect)="highlightGoalNodes()"
            (zoomOnNode)="zoomToNode($event)"
            (deleteGoalNode)="removeHighlight()"
         >
         </app-node-inspector>
      </div>
   </div>
</div>

<p-toast></p-toast>
