import { NgModule } from '@angular/core';
import { CustomComponentsModule } from './components/custom-components.module';
import { PRIMENGModule } from './prime-ng/prime-ng.module';
import { PipeModule } from './pipes/pipe.module';
import { TranslationModule } from '../core/services/i18n/translation.module';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [],
  imports: [
    CustomComponentsModule,
    PRIMENGModule,
    PipeModule,
    TranslationModule,
    TranslateModule,
    DynamicDialogModule,
  ],
  exports: [PRIMENGModule, PipeModule, CustomComponentsModule, TranslateModule, TranslationModule],
  providers: [DynamicDialogConfig, DynamicDialogRef],
})
export class SharedModule {}
